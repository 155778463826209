<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="resetForm('ruleForm')">
		<div slot="title" class="postion">
			<i class="iconfont icon-bianji1"></i>
			<span class="download">{{ ruleForm.id ? '编辑' : '新增' }}类别字典</span>
		</div>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="编号" prop="code">
				<el-input v-model.trim="ruleForm.code" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="名称" prop="title">
				<el-input v-model.trim="ruleForm.title" placeholder="请输入"></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button @click="resetForm('ruleForm')">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	data() {
		return {
			createdVisible: false,
			ruleForm: {
				title: '',
				code: '',
			},
			rules: {
				title: [{ required: true, message: '请输入', trigger: 'blur' }],
				code: [{ required: true, message: '请输入', trigger: 'blur' }],
			},
			saveLoading: false,
			codeId: null,
		};
	},
	created() {},
	methods: {
		init(data) {
			this.createdVisible = true;
			this.codeId = data.id;
			if (data.row) {
				this.$nextTick(() => {
					this.ruleForm = JSON.parse(JSON.stringify(data.row));
					return;
				});
			}
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let data = {
						...this.ruleForm,
						dictionaryId: this.codeId,
					};
					let requestType = this.ruleForm.id
						? this.$http.put(this.api.getDictionaryItems + `/${data.id}.json`, data)
						: this.$http.post(this.api.getDictionaryItems + '.json', data);
					requestType
						.then((res) => {
							if (res.data && res.data.success) {
								this.saveLoading = false;
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.resetForm('ruleForm');
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				}
			});
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.createdVisible = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #5374f0;
	}
}
/deep/ .el-form {
	.el-form-item {
		margin-right: 0;
		.el-select,
		.el-input {
			width: 100%;
		}
	}
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .el-button {
	margin-left: 16px;
}
</style>